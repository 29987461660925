import { HStack } from '@/components/HStack'
import { AuthLayout } from '@/components/layout/AuthLayout'
import { __unsafe_useAuthStore } from '@/components/store/AuthStore'
import type { IFieldError } from '@/lib/IFieldError'
import { cn } from '@/lib/cn'
import { isValidEmail } from '@/lib/isValidEmail'
import { IconArrowRight, IconLoader } from '@tabler/icons-react'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'

export default function StartPage() {
  const router = useRouter()

  const { accessToken } = __unsafe_useAuthStore()

  useEffect(() => {
    if (accessToken) {
      router.replace({ pathname: '/' }).catch(e => console.error(e))
    }
  }, [accessToken])

  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [phone, setPhone] = useState('')
  const [focused, setFocused] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    setTimeout(() => {
      document.getElementById('phone')?.focus()
    }, 500)
  }, [])

  const send = async () => {
    const trimmed = phone.trim().toLowerCase()
    // if (!isValidEmail(trimmed)) {
    //   return
    // }

    try {
      const response = await fetch('/api/start', {
        body: JSON.stringify({
          phone: '+1' + trimmed,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      })

      // Assert the response type to be 'Response'
      const resp = response as Response

      if (resp.status >= 500) {
        throw new Error('Network response was not ok')
      }

      const json = (await resp.json()) as {
        verificationId?: string
        errors?: IFieldError[]
        phone?: string
      }

      if (json.errors) {
        setError(json.errors[0].message)
      } else if (json.verificationId) {
        await router.push({
          pathname: '/verify',
          query: { ...router.query, verification: json.verificationId, phone: json.phone },
        })
      }
    } catch (error) {
      // Handle fetch or timeout error
      console.error('An error occurred:', error)
      // Set an error state or show a message to the user here
      setError('There was an error — please try again!')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (error.length > 0) {
      setError('')
    }
  }, [phone])

  return (
    <AuthLayout
      pageTitle="Start"
      heroText="Get started"
      secondaryText="Enter the phone you use at work"
      errorText={error}
    >
      <HStack
        className={cn(
          'w-full h-[40px] rounded-[8px] border-[1px] overflow-hidden flex-shrink-0 items-center px-[4px]',
          focused
            ? 'border-blue-300 dark:border-blue-500 bg-blue-300/10 dark:bg-blue-500/10'
            : 'border-primary/20 hover:border-primary/40 bg-white/40 dark:bg-neutral-500/5'
        )}
      >
        <input
          className="px-[8px] h-full w-full border-none outline-none text-[14px] bg-transparent"
          value={phone}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          type={'tel'}
          onChange={e => setPhone(e.target.value)}
          id={'phone'}
          placeholder="(123) 456-7890"
          autoCapitalize="none"
          autoComplete="phone"
          autoCorrect="off"
          disabled={isLoading}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              send()
            }
          }}
        />
        <HStack
          onClick={() => send()}
          className={cn(
            'h-[32px] w-[32px] items-center justify-center rounded-[4px]',
            isValidEmail(phone) ? 'opacity-90 hover:opacity-100 bg-blue-500/90' : 'opacity-30 bg-primary/50'
          )}
        >
          {isLoading ? (
            <IconLoader size={18} className="text-primary-foreground animate-spin" />
          ) : (
            <IconArrowRight size={18} className="text-primary-foreground" />
          )}
        </HStack>
      </HStack>
    </AuthLayout>
  )
}
