import isEmail from 'validator/lib/isEmail'

export function isValidEmail(email: string): boolean {
  return isEmail(email)
  // Validate the basic email format first
  // const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
  // if (!emailPattern.test(email)) {
  //   return false
  // }

  // // Extract the domain and TLD from the email
  // const domain = email.split('@')[1]
  // const domainParts = domain.split('.')
  // const tld = domainParts.slice(-1)[0] // Gets the last part (TLD)

  // // Check if TLD is in the list of valid TLDs
  // return ALL_TLDS.includes(tld.toLowerCase())
}
