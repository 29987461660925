import { UserContext } from '@/components/auth/UserContext'
import { __unsafe_useAuthStore } from '@/components/store/AuthStore'
import { useFetchMe, type MeFragment } from '@/graphql/generated/query.types'
import StartPage from '@/pages/start'
import VerifyPage from '@/pages/verify'
import WaitlistPage from '@/pages/waitlist'
import { useRouter } from 'next/router'
import { useEffect, useRef, useState, type PropsWithChildren } from 'react'

/**
 * Page component keeps track of the current user, and provides it to downstream components via the
 * UserContext. If there is no user, renders the Login page.
 */
export function AuthWrapper({ children }: PropsWithChildren<unknown>) {
  const router = useRouter()
  const [loading, setLoading] = useState(true)

  const { accessToken, login } = __unsafe_useAuthStore()

  const fetchRef = useRef(false)

  useEffect(() => {
    if (fetchRef.current) {
      return
    }
    fetchRef.current = true // prevent multiple fetches

    fetch('/api/refresh_token', {
      method: 'POST',
      credentials: 'include',
    })
      .then(async x => {
        const { accessToken } = await x.json()
        login(accessToken)
        // __unsafe_useAuthStore.getState()
        // setAccessToken(accessToken)
        setLoading(false)
      })
      .catch(e => console.error(e))
  }, [router.pathname])

  if (loading) {
    return null
  }

  if (!loading && !accessToken) {
    if (router.pathname.startsWith('/verify')) {
      return <VerifyPage />
    } else if (router.pathname.startsWith('/start')) {
      return <StartPage />
    } else if (router.pathname.startsWith('/waitlist')) {
      return <WaitlistPage />
    }

    router.push('/start')
    return null
  }

  if (router.route === '/_error') {
    return <>{children}</>
  }

  return <UserContextWrapper>{children}</UserContextWrapper>
}

function UserContextWrapper({ children }: PropsWithChildren<unknown>) {
  const { data, previousData, refetch, error } = useFetchMe() // fetch to load apollo cache, use the auth token user to avoid waiting for render
  const meFragment: MeFragment | null | undefined = data?.me ?? previousData?.me

  return meFragment ? <UserContext.Provider value={meFragment}>{children}</UserContext.Provider> : null
}
