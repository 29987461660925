import { cn } from '@/lib/cn'
import type { PropsWithChildren } from 'react'

export type Spacing =
  | 0
  | 0.5
  | 1
  | 1.5
  | 2
  | 2.5
  | 3
  | 3.5
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 14
  | 16
  | 20
  | 24
  | 28
  | 32
  | 36
  | 40
  | 44
  | 48
  | 52
  | 56
  | 60
  | 64
  | 72
  | 80
  | 96

interface VStackProps {
  className?: string
  spacing?: Spacing
  onClick?: () => void
}

/**
 * A horizontal stack of components, using flexbox.
 */
export function VStack({ children, spacing, className, onClick }: PropsWithChildren<VStackProps>) {
  return (
    <div
      className={cn(
        'align-center flex h-full flex-col',
        spacing === 1 && 'space-y-1',
        spacing === 2 && 'space-y-2',
        spacing === 4 && 'space-y-4',
        className
      )}
      onClick={onClick}
    >
      {children}
    </div>
  )
}
