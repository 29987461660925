import { HStack } from '@/components/HStack'
import { AuthLayout } from '@/components/layout/AuthLayout'
import { __unsafe_useAuthStore } from '@/components/store/AuthStore'
import { IconArrowRight } from '@tabler/icons-react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

export default function WaitlistPage() {
  const router = useRouter()

  const { accessToken } = __unsafe_useAuthStore()

  useEffect(() => {
    if (accessToken) {
      router.replace({ pathname: '/' }).catch(e => console.error(e))
    }
  }, [accessToken])

  return (
    <AuthLayout
      pageTitle="Waitlist"
      heroText="You're on the waitlist"
      secondaryText="We'll let you know when your Fadeaway account is ready."
      errorText=""
    >
      <Link href="https://www.fadeaway.com">
        <HStack className="w-full cursor-pointer h-[40px] rounded-[8px] border-[1px] overflow-hidden flex-shrink-0 items-center px-[4px] bg-primary/80 hover:bg-primary text-primary-foreground/80 hover:text-primary-foreground justify-center space-x-[4px]">
          <div className="text-[14px] font-semibold">Go home</div>
          <IconArrowRight size={16} />
        </HStack>
      </Link>
    </AuthLayout>
  )
}
