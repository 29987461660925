import '@/styles/globals.css'
// import '@fontsource/inter/variable.css'
// import { GeistMono, GeistSans } from 'geist/font'
import { ThemeProvider } from '@/components/ThemeProvider'
import { AuthWrapper } from '@/components/auth/AuthWrapper'
import { Toaster } from '@/components/ui/sonner'
// import { api, apiQueryClient, apiQueryContext } from '@/data/trpcClient'
import { apolloClient } from '@/graphql/apollo'
import { ApolloProvider } from '@apollo/client'
// import { httpBatchLink, loggerLink } from '@repo/trpc/client'
// import { QueryClientProvider } from '@tanstack/react-query'
// import type { TRPCLink } from '@trpc/client'
// import { Analytics } from '@vercel/analytics/react'
import type { AppProps } from 'next/app'

export default function App({ Component, pageProps }: AppProps) {
  return (
    <ThemeProvider attribute="class" defaultTheme="dark" enableSystem>
      <ApolloProvider client={apolloClient}>
        <AuthWrapper>
          <Component {...pageProps} />
          <Toaster />
          {/* <Analytics /> */}
          {/* <TailwindIndicator /> */}
        </AuthWrapper>
      </ApolloProvider>
    </ThemeProvider>
  )
}

// https://github.com/axiomhq/next-axiom?utm_source=twitter&utm_medium=post#web-vitals
// export { reportWebVitals } from 'next-axiom'
