import { VStack } from '@/components/VStack'
import { cn } from '@/lib/cn'
import Head from 'next/head'

interface AuthLayoutProps {
  className?: string
  pageTitle: string
  heroText: string
  secondaryText: string
  errorText: string
  helperText?: string
  children?: React.ReactNode
}

export const AuthLayout = (props: AuthLayoutProps) => {
  const { pageTitle, children, className, heroText, secondaryText, errorText, helperText } = props

  return (
    <>
      <Head>
        <title>Fadeaway — {pageTitle}</title>
      </Head>
      <VStack className={cn('h-screen w-screen overflow-hidden', className)}>
        <main className="flex h-full w-full flex-col overflow-hidden">
          <VStack className="h-screen w-screen items-center justify-center overflow-hidden">
            <VStack className="h-fit w-full max-w-[400px] p-[40px] rounded-[8px] border-[1px] border-primary/20 space-y-[16px] bg-white dark:bg-black">
              <VStack className="w-full items-center space-y-[8px]">
                <div className="text-[24px] font-semibold text-primary/90 tracking-tight leading-none">{heroText}</div>
                <div className="text-[14px] text-primary/50 text-center">{secondaryText}</div>
              </VStack>
              <VStack>{children}</VStack>
              {errorText.length > 0 && (
                <p
                  data-testid="user-email-form-error"
                  className="w-full text-center pt-[8px] font-medium text-red-500 text-[13px]"
                >
                  {errorText}
                </p>
              )}
              {helperText && <p className="w-full text-center font-medium text-primary/30 text-[12px]">{helperText}</p>}
            </VStack>
          </VStack>
        </main>
      </VStack>
    </>
  )
}
