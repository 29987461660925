import { __unsafe_useAuthStore } from '@/components/store/AuthStore'
import {
  ApolloClient,
  ApolloLink,
  type FieldPolicy,
  type FieldReadFunction,
  HttpLink,
  InMemoryCache,
  defaultDataIdFromObject,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { TokenRefreshLink } from 'apollo-link-token-refresh'
import jwtDecode, { type JwtPayload } from 'jwt-decode'
import { toast } from 'sonner'

const httpLink = new HttpLink({
  uri: () => '/api/graphql',
  credentials: 'include',
  fetch: (uri: any, options: any) => {
    const reqBody = JSON.parse(options.body as string)
    const opName = reqBody.operationName
    return fetch(`${uri}?opName=${opName}`, {
      ...options,
      headers: { authorization: `bearer ${__unsafe_useAuthStore.getState().accessToken}`, ...(options.headers || {}) },
    })
  },
})

function paginatedTypePolicy(
  keyArgs: string[] | undefined = undefined,
  sortOrder = 'asc'
): FieldPolicy<any> | FieldReadFunction<any> {
  return {
    keyArgs: keyArgs ? keyArgs : false,
    // keyFields: [],

    merge(existing, incoming, { readField }) {
      const items = existing ? { ...existing.items } : {}
      const incomingItems = readField('items', incoming) as any[]
      incomingItems.forEach((item: any) => {
        items[readField('id', item) as string] = item
      })
      return {
        __typename: readField('__typename', incoming),
        cursor: incoming.cursor,
        items,
      }
    },

    read(existing) {
      if (existing) {
        const sortNum = sortOrder === 'asc' ? -1 : 1
        return {
          __typename: existing.__typename,
          cursor: existing.cursor,
          items: Object.values(existing.items).sort((a: any, b: any) =>
            a.timeCreated < b.timeCreated ? sortNum : -sortNum
          ),
        }
      }
    },
  }
}

export const cache = new InMemoryCache({
  // https://www.apollographql.com/docs/react/caching/cache-configuration/#customizing-cache-ids
  dataIdFromObject(responseObject) {
    if (responseObject.id) {
      return `${responseObject.__typename}:${responseObject.id}`
    }
    return defaultDataIdFromObject(responseObject)
  },
  typePolicies: {
    Query: {
      fields: {
        jobs: paginatedTypePolicy([], 'desc'),
      },
    },
  },
})

const link = ApolloLink.from([
  new TokenRefreshLink({
    accessTokenField: 'accessToken',
    isTokenValidOrUndefined: () => {
      const token = __unsafe_useAuthStore.getState().accessToken

      if (!token) {
        return false
      }

      try {
        const { exp } = jwtDecode<JwtPayload>(token)
        if (Date.now() >= exp! * 1000) {
          return false
        }
        return true
      } catch {
        return false
      }
    },
    fetchAccessToken: () => {
      return fetch('/api/refresh_token', {
        method: 'POST',
        credentials: 'include',
      })
    },
    handleFetch: accessToken => {
      __unsafe_useAuthStore.getState().login(accessToken)
    },
    handleError: err => {
      console.warn('Your refresh token is invalid. Try to relogin')
      console.error(err)
    },
  }),
  onError(({ graphQLErrors, networkError }) => {
    const token = __unsafe_useAuthStore.getState().accessToken
    if (!token) {
      return // defensive check, the token should be defined
    }

    const errors: string[] = []
    if (graphQLErrors) {
      graphQLErrors.map(({ message, path }) => {
        const err = message
        console.error(`[GraphQL error]: ${path}: ${message}`)
        errors.push(err)
      })
    }
    if (networkError) {
      const err = `${networkError.name} ${networkError.message}`
      if (`${err}`.includes('Received status code 403')) {
        return // don't toast; this is a valid 403 error when the 15min access token expires
      }
      console.error(`[GraphQL network error]: ${err}`)
      errors.push(err)
    }

    for (const e of errors) {
      // deduplication on message string, we don't need to show the same error twice
      const [title, ...description] = e.split('\n')
      toast.message(title, {
        description,
      })
    }
  }),
  // requestLink,
  httpLink,
])

// consider using https://github.com/adamsoffer/next-apollo
// https://www.rockyourcode.com/nextjs-with-apollo-ssr-cookies-and-typescript/
// https://hasura.io/learn/graphql/nextjs-fullstack-serverless/apollo-client/
export const apolloClient = new ApolloClient({
  link,
  cache,
  // queryDeduplication: false,
  // defaultOptions: {
  //   query: {
  //     fetchPolicy: 'cache-first',
  //   },
  // },
})

// for Chrome devtools / local debugging convenience
const windowAny = typeof window !== 'undefined' ? window : ({} as any)
windowAny.apolloCache = cache
windowAny.apolloClient = apolloClient
