/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: string;
};

export enum ConversationMessageRole {
  assistant = 'assistant',
  system = 'system',
  tool = 'tool',
  user = 'user'
}

export type GqlChild = {
  __typename?: 'GQLChild';
  avatarId?: Maybe<Scalars['String']>;
  conversation?: Maybe<GqlConversation>;
  deleted: Scalars['Boolean'];
  familyId: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
};

export type GqlChildCreator = {
  avatarId?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type GqlChildUpdater = {
  avatarId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
};

export type GqlConversation = {
  __typename?: 'GQLConversation';
  child: GqlChild;
  childId: Scalars['String'];
  familyId: Scalars['String'];
  id: Scalars['String'];
  livekitRoomJwt?: Maybe<Scalars['String']>;
  livekitRoomName: Scalars['String'];
  livekitRoomUrl?: Maybe<Scalars['String']>;
  timeCreated: Scalars['DateTime'];
  timeEnded?: Maybe<Scalars['String']>;
};

export type GqlConversationCreator = {
  childId: Scalars['String'];
};

export type GqlConversationMessage = {
  __typename?: 'GQLConversationMessage';
  content: Scalars['String'];
  conversationId: Scalars['String'];
  finishReason?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  role: ConversationMessageRole;
  timeCreated: Scalars['DateTime'];
  toolCallId?: Maybe<Scalars['String']>;
  toolCalls?: Maybe<Array<GqlConversationMessageToolCall>>;
  user: GqlUser;
  userId: Scalars['String'];
};

export type GqlConversationMessageCreator = {
  content: Scalars['String'];
  conversationId: Scalars['String'];
  role: ConversationMessageRole;
  userId: Scalars['String'];
};

export type GqlConversationMessageToolCall = {
  __typename?: 'GQLConversationMessageToolCall';
  function: GqlConversationMessageToolCallFunction;
  id: Scalars['String'];
  type: Scalars['String'];
};

export type GqlConversationMessageToolCallFunction = {
  __typename?: 'GQLConversationMessageToolCallFunction';
  arguments: Scalars['String'];
  name: Scalars['String'];
};

export type GqlDevice = {
  __typename?: 'GQLDevice';
  appBuild?: Maybe<Scalars['String']>;
  appVersion?: Maybe<Scalars['String']>;
  deviceBatteryLevel?: Maybe<Scalars['Float']>;
  deviceGuid: Scalars['String'];
  deviceModel?: Maybe<Scalars['String']>;
  deviceOSVersion?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isCharging: Scalars['Boolean'];
  sandbox: Scalars['Boolean'];
  timeCreated: Scalars['DateTime'];
  timeUpdated?: Maybe<Scalars['String']>;
  timeZoneName?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  userNotificationPushToken?: Maybe<Scalars['String']>;
  voipPushToken?: Maybe<Scalars['String']>;
};

export type GqlDeviceCreator = {
  appBuild?: InputMaybe<Scalars['String']>;
  appVersion?: InputMaybe<Scalars['String']>;
  deviceBatteryLevel?: InputMaybe<Scalars['Float']>;
  deviceGuid: Scalars['String'];
  deviceModel?: InputMaybe<Scalars['String']>;
  deviceOSVersion?: InputMaybe<Scalars['String']>;
  isCharging: Scalars['Boolean'];
  sandbox: Scalars['Boolean'];
  timeZoneName?: InputMaybe<Scalars['String']>;
  userNotificationPushToken?: InputMaybe<Scalars['String']>;
  voipPushToken?: InputMaybe<Scalars['String']>;
};

export type GqlDeviceUpdater = {
  appBuild?: InputMaybe<Scalars['String']>;
  appVersion?: InputMaybe<Scalars['String']>;
  deviceBatteryLevel?: InputMaybe<Scalars['Float']>;
  deviceModel?: InputMaybe<Scalars['String']>;
  deviceOSVersion?: InputMaybe<Scalars['String']>;
  isCharging?: InputMaybe<Scalars['Boolean']>;
  timeZoneName?: InputMaybe<Scalars['String']>;
  userNotificationPushToken?: InputMaybe<Scalars['String']>;
  voipPushToken?: InputMaybe<Scalars['String']>;
};

export type GqlFamily = {
  __typename?: 'GQLFamily';
  avatarId?: Maybe<Scalars['String']>;
  children: Array<GqlChild>;
  id: Scalars['String'];
  name: Scalars['String'];
  users: Array<GqlUser>;
};

export type GqlFamilyUpdater = {
  avatarId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type GqlMeUpdater = {
  avatarId?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  verificationInput?: InputMaybe<GqlVerificationInput>;
};

export type GqlUser = {
  __typename?: 'GQLUser';
  avatarId?: Maybe<Scalars['String']>;
  deleted: Scalars['Boolean'];
  developer: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
  family: GqlFamily;
  familyId: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  isSystem: Scalars['Boolean'];
  lastName: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  timeCreated: Scalars['DateTime'];
};

export type GqlVerification = {
  __typename?: 'GQLVerification';
  addr: Scalars['String'];
  id: Scalars['String'];
  timeCreated: Scalars['DateTime'];
  timeExpires: Scalars['Int'];
  type: VerificationType;
  userId?: Maybe<Scalars['String']>;
  verified: Scalars['Boolean'];
};

export type GqlVerificationCreator = {
  addr: Scalars['String'];
};

export type GqlVerificationInput = {
  code: Scalars['String'];
  verificationId: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createChild: GqlChild;
  createConversation: GqlConversation;
  createConversationMessage: GqlConversationMessage;
  createDevice: GqlDevice;
  createVerification: GqlVerification;
  deleteChild: GqlChild;
  deleteMe: GqlUser;
  leaveConversation: GqlConversation;
  removeMeAvatar: GqlUser;
  updateChild: GqlChild;
  updateDevice: GqlDevice;
  updateFamily: GqlFamily;
  updateMe: GqlUser;
  updateMeWithVerification: GqlUser;
};


export type MutationCreateChildArgs = {
  creator: GqlChildCreator;
};


export type MutationCreateConversationArgs = {
  creator: GqlConversationCreator;
};


export type MutationCreateConversationMessageArgs = {
  creator: GqlConversationMessageCreator;
};


export type MutationCreateDeviceArgs = {
  creator: GqlDeviceCreator;
};


export type MutationCreateVerificationArgs = {
  creator: GqlVerificationCreator;
};


export type MutationDeleteChildArgs = {
  id: Scalars['String'];
};


export type MutationLeaveConversationArgs = {
  id: Scalars['String'];
};


export type MutationUpdateChildArgs = {
  id: Scalars['String'];
  updater: GqlChildUpdater;
};


export type MutationUpdateDeviceArgs = {
  id: Scalars['String'];
  updater: GqlDeviceUpdater;
};


export type MutationUpdateFamilyArgs = {
  updater: GqlFamilyUpdater;
};


export type MutationUpdateMeArgs = {
  updater: GqlMeUpdater;
};


export type MutationUpdateMeWithVerificationArgs = {
  code: Scalars['String'];
  verificationId: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  child: GqlChild;
  children: Array<GqlChild>;
  conversationByChildId?: Maybe<GqlConversation>;
  conversationById: GqlConversation;
  conversationByLivekitRoomName: GqlConversation;
  conversationMessage: GqlConversationMessage;
  conversationMessages: Array<GqlConversationMessage>;
  family: GqlFamily;
  me: GqlUser;
  system: GqlUser;
  user: GqlUser;
  verification: GqlVerification;
};


export type QueryChildArgs = {
  id: Scalars['String'];
};


export type QueryConversationByChildIdArgs = {
  childId: Scalars['String'];
};


export type QueryConversationByIdArgs = {
  id: Scalars['String'];
};


export type QueryConversationByLivekitRoomNameArgs = {
  livekitRoomName: Scalars['String'];
};


export type QueryConversationMessageArgs = {
  id: Scalars['String'];
};


export type QueryConversationMessagesArgs = {
  conversationId: Scalars['String'];
};


export type QueryUserArgs = {
  id: Scalars['String'];
};


export type QueryVerificationArgs = {
  id: Scalars['String'];
};

export enum VerificationType {
  start = 'start',
  update_address = 'update_address'
}

export type ChildFragment = { __typename?: 'GQLChild', id: string, firstName: string, lastName: string, avatarId?: string | null, deleted: boolean, familyId: string };

export type ConversationFragment = { __typename?: 'GQLConversation', id: string, familyId: string, childId: string, timeEnded?: string | null, livekitRoomName: string, livekitRoomJwt?: string | null, livekitRoomUrl?: string | null };

export type ConversationMessageFragment = { __typename?: 'GQLConversationMessage', id: string, content: string, conversationId: string, timeCreated: string, role: ConversationMessageRole, toolCallId?: string | null, toolCalls?: Array<{ __typename?: 'GQLConversationMessageToolCall', id: string, type: string, function: { __typename?: 'GQLConversationMessageToolCallFunction', name: string, arguments: string } }> | null };

export type DeviceFragment = { __typename?: 'GQLDevice', id: string, deviceGuid: string, sandbox: boolean, voipPushToken?: string | null, userNotificationPushToken?: string | null, timeZoneName?: string | null, appVersion?: string | null, appBuild?: string | null, deviceModel?: string | null, deviceOSVersion?: string | null, deviceBatteryLevel?: number | null, isCharging: boolean, timeUpdated?: string | null, userId: string };

export type FamilyFragment = { __typename?: 'GQLFamily', id: string, name: string, avatarId?: string | null };

export type FamilyFullFragment = { __typename?: 'GQLFamily', id: string, name: string, avatarId?: string | null, children: Array<{ __typename?: 'GQLChild', id: string, firstName: string, lastName: string, avatarId?: string | null, deleted: boolean, familyId: string }>, users: Array<{ __typename?: 'GQLUser', id: string, phone: string, email?: string | null, avatarId?: string | null, firstName: string, lastName: string, name: string }> };

export type MeFragment = { __typename?: 'GQLUser', developer: boolean, id: string, phone: string, email?: string | null, avatarId?: string | null, firstName: string, lastName: string, name: string, family: { __typename?: 'GQLFamily', id: string, name: string, avatarId?: string | null, children: Array<{ __typename?: 'GQLChild', id: string, firstName: string, lastName: string, avatarId?: string | null, deleted: boolean, familyId: string }>, users: Array<{ __typename?: 'GQLUser', id: string, phone: string, email?: string | null, avatarId?: string | null, firstName: string, lastName: string, name: string }> } };

export type UserFragment = { __typename?: 'GQLUser', id: string, phone: string, email?: string | null, avatarId?: string | null, firstName: string, lastName: string, name: string };

export type CreateConversationVariables = Exact<{
  creator: GqlConversationCreator;
}>;


export type CreateConversation = { __typename?: 'Mutation', createConversation: { __typename?: 'GQLConversation', id: string, familyId: string, childId: string, timeEnded?: string | null, livekitRoomName: string, livekitRoomJwt?: string | null, livekitRoomUrl?: string | null } };

export type CreateConversationMessageVariables = Exact<{
  creator: GqlConversationMessageCreator;
}>;


export type CreateConversationMessage = { __typename?: 'Mutation', createConversationMessage: { __typename?: 'GQLConversationMessage', id: string, content: string, conversationId: string, timeCreated: string, role: ConversationMessageRole, toolCallId?: string | null, toolCalls?: Array<{ __typename?: 'GQLConversationMessageToolCall', id: string, type: string, function: { __typename?: 'GQLConversationMessageToolCallFunction', name: string, arguments: string } }> | null } };

export type CreateDeviceVariables = Exact<{
  creator: GqlDeviceCreator;
}>;


export type CreateDevice = { __typename?: 'Mutation', createDevice: { __typename?: 'GQLDevice', id: string, deviceGuid: string, sandbox: boolean, voipPushToken?: string | null, userNotificationPushToken?: string | null, timeZoneName?: string | null, appVersion?: string | null, appBuild?: string | null, deviceModel?: string | null, deviceOSVersion?: string | null, deviceBatteryLevel?: number | null, isCharging: boolean, timeUpdated?: string | null, userId: string } };

export type LeaveConversationVariables = Exact<{
  id: Scalars['String'];
}>;


export type LeaveConversation = { __typename?: 'Mutation', leaveConversation: { __typename?: 'GQLConversation', id: string, familyId: string, childId: string, timeEnded?: string | null, livekitRoomName: string, livekitRoomJwt?: string | null, livekitRoomUrl?: string | null } };

export type UpdateDeviceVariables = Exact<{
  id: Scalars['String'];
  updater: GqlDeviceUpdater;
}>;


export type UpdateDevice = { __typename?: 'Mutation', updateDevice: { __typename?: 'GQLDevice', id: string, deviceGuid: string, sandbox: boolean, voipPushToken?: string | null, userNotificationPushToken?: string | null, timeZoneName?: string | null, appVersion?: string | null, appBuild?: string | null, deviceModel?: string | null, deviceOSVersion?: string | null, deviceBatteryLevel?: number | null, isCharging: boolean, timeUpdated?: string | null, userId: string } };

export type UpdateMeVariables = Exact<{
  updater: GqlMeUpdater;
}>;


export type UpdateMe = { __typename?: 'Mutation', updateMe: { __typename?: 'GQLUser', developer: boolean, id: string, phone: string, email?: string | null, avatarId?: string | null, firstName: string, lastName: string, name: string, family: { __typename?: 'GQLFamily', id: string, name: string, avatarId?: string | null, children: Array<{ __typename?: 'GQLChild', id: string, firstName: string, lastName: string, avatarId?: string | null, deleted: boolean, familyId: string }>, users: Array<{ __typename?: 'GQLUser', id: string, phone: string, email?: string | null, avatarId?: string | null, firstName: string, lastName: string, name: string }> } } };

export type FetchConversationByChildIdVariables = Exact<{
  childId: Scalars['String'];
}>;


export type FetchConversationByChildId = { __typename?: 'Query', conversationByChildId?: { __typename?: 'GQLConversation', id: string, familyId: string, childId: string, timeEnded?: string | null, livekitRoomName: string, livekitRoomJwt?: string | null, livekitRoomUrl?: string | null } | null };

export type FetchFamilyVariables = Exact<{ [key: string]: never; }>;


export type FetchFamily = { __typename?: 'Query', family: { __typename?: 'GQLFamily', id: string, name: string, avatarId?: string | null } };

export type FetchMeVariables = Exact<{ [key: string]: never; }>;


export type FetchMe = { __typename?: 'Query', me: { __typename?: 'GQLUser', developer: boolean, id: string, phone: string, email?: string | null, avatarId?: string | null, firstName: string, lastName: string, name: string, family: { __typename?: 'GQLFamily', id: string, name: string, avatarId?: string | null, children: Array<{ __typename?: 'GQLChild', id: string, firstName: string, lastName: string, avatarId?: string | null, deleted: boolean, familyId: string }>, users: Array<{ __typename?: 'GQLUser', id: string, phone: string, email?: string | null, avatarId?: string | null, firstName: string, lastName: string, name: string }> } } };

export const ConversationFragment = gql`
    fragment ConversationFragment on GQLConversation {
  id
  familyId
  childId
  timeEnded
  livekitRoomName
  livekitRoomJwt
  livekitRoomUrl
}
    `;
export const ConversationMessageFragment = gql`
    fragment ConversationMessageFragment on GQLConversationMessage {
  id
  content
  conversationId
  timeCreated
  role
  toolCalls {
    id
    type
    function {
      name
      arguments
    }
  }
  toolCallId
}
    `;
export const DeviceFragment = gql`
    fragment DeviceFragment on GQLDevice {
  id
  deviceGuid
  sandbox
  voipPushToken
  userNotificationPushToken
  timeZoneName
  appVersion
  appBuild
  deviceModel
  deviceOSVersion
  deviceBatteryLevel
  isCharging
  timeUpdated
  userId
}
    `;
export const UserFragment = gql`
    fragment UserFragment on GQLUser {
  id
  phone
  email
  avatarId
  firstName
  lastName
  name
}
    `;
export const FamilyFragment = gql`
    fragment FamilyFragment on GQLFamily {
  id
  name
  avatarId
}
    `;
export const ChildFragment = gql`
    fragment ChildFragment on GQLChild {
  id
  firstName
  lastName
  avatarId
  deleted
  familyId
}
    `;
export const FamilyFullFragment = gql`
    fragment FamilyFullFragment on GQLFamily {
  ...FamilyFragment
  children {
    ...ChildFragment
  }
  users {
    ...UserFragment
  }
}
    ${FamilyFragment}
${ChildFragment}
${UserFragment}`;
export const MeFragment = gql`
    fragment MeFragment on GQLUser {
  ...UserFragment
  developer
  family {
    ...FamilyFullFragment
  }
}
    ${UserFragment}
${FamilyFullFragment}`;
export const CreateConversationDocument = gql`
    mutation CreateConversation($creator: GQLConversationCreator!) {
  createConversation(creator: $creator) {
    ...ConversationFragment
  }
}
    ${ConversationFragment}`;
export type CreateConversationMutationFn = Apollo.MutationFunction<CreateConversation, CreateConversationVariables>;

/**
 * __useCreateConversation__
 *
 * To run a mutation, you first call `useCreateConversation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConversation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConversation, { data, loading, error }] = useCreateConversation({
 *   variables: {
 *      creator: // value for 'creator'
 *   },
 * });
 */
export function useCreateConversation(baseOptions?: Apollo.MutationHookOptions<CreateConversation, CreateConversationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateConversation, CreateConversationVariables>(CreateConversationDocument, options);
      }
export type CreateConversationHookResult = ReturnType<typeof useCreateConversation>;
export type CreateConversationMutationResult = Apollo.MutationResult<CreateConversation>;
export type CreateConversationMutationOptions = Apollo.BaseMutationOptions<CreateConversation, CreateConversationVariables>;
export const CreateConversationMessageDocument = gql`
    mutation CreateConversationMessage($creator: GQLConversationMessageCreator!) {
  createConversationMessage(creator: $creator) {
    ...ConversationMessageFragment
  }
}
    ${ConversationMessageFragment}`;
export type CreateConversationMessageMutationFn = Apollo.MutationFunction<CreateConversationMessage, CreateConversationMessageVariables>;

/**
 * __useCreateConversationMessage__
 *
 * To run a mutation, you first call `useCreateConversationMessage` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConversationMessage` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConversationMessage, { data, loading, error }] = useCreateConversationMessage({
 *   variables: {
 *      creator: // value for 'creator'
 *   },
 * });
 */
export function useCreateConversationMessage(baseOptions?: Apollo.MutationHookOptions<CreateConversationMessage, CreateConversationMessageVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateConversationMessage, CreateConversationMessageVariables>(CreateConversationMessageDocument, options);
      }
export type CreateConversationMessageHookResult = ReturnType<typeof useCreateConversationMessage>;
export type CreateConversationMessageMutationResult = Apollo.MutationResult<CreateConversationMessage>;
export type CreateConversationMessageMutationOptions = Apollo.BaseMutationOptions<CreateConversationMessage, CreateConversationMessageVariables>;
export const CreateDeviceDocument = gql`
    mutation CreateDevice($creator: GQLDeviceCreator!) {
  createDevice(creator: $creator) {
    ...DeviceFragment
  }
}
    ${DeviceFragment}`;
export type CreateDeviceMutationFn = Apollo.MutationFunction<CreateDevice, CreateDeviceVariables>;

/**
 * __useCreateDevice__
 *
 * To run a mutation, you first call `useCreateDevice` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDevice` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDevice, { data, loading, error }] = useCreateDevice({
 *   variables: {
 *      creator: // value for 'creator'
 *   },
 * });
 */
export function useCreateDevice(baseOptions?: Apollo.MutationHookOptions<CreateDevice, CreateDeviceVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDevice, CreateDeviceVariables>(CreateDeviceDocument, options);
      }
export type CreateDeviceHookResult = ReturnType<typeof useCreateDevice>;
export type CreateDeviceMutationResult = Apollo.MutationResult<CreateDevice>;
export type CreateDeviceMutationOptions = Apollo.BaseMutationOptions<CreateDevice, CreateDeviceVariables>;
export const LeaveConversationDocument = gql`
    mutation LeaveConversation($id: String!) {
  leaveConversation(id: $id) {
    ...ConversationFragment
  }
}
    ${ConversationFragment}`;
export type LeaveConversationMutationFn = Apollo.MutationFunction<LeaveConversation, LeaveConversationVariables>;

/**
 * __useLeaveConversation__
 *
 * To run a mutation, you first call `useLeaveConversation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveConversation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveConversation, { data, loading, error }] = useLeaveConversation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLeaveConversation(baseOptions?: Apollo.MutationHookOptions<LeaveConversation, LeaveConversationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LeaveConversation, LeaveConversationVariables>(LeaveConversationDocument, options);
      }
export type LeaveConversationHookResult = ReturnType<typeof useLeaveConversation>;
export type LeaveConversationMutationResult = Apollo.MutationResult<LeaveConversation>;
export type LeaveConversationMutationOptions = Apollo.BaseMutationOptions<LeaveConversation, LeaveConversationVariables>;
export const UpdateDeviceDocument = gql`
    mutation UpdateDevice($id: String!, $updater: GQLDeviceUpdater!) {
  updateDevice(id: $id, updater: $updater) {
    ...DeviceFragment
  }
}
    ${DeviceFragment}`;
export type UpdateDeviceMutationFn = Apollo.MutationFunction<UpdateDevice, UpdateDeviceVariables>;

/**
 * __useUpdateDevice__
 *
 * To run a mutation, you first call `useUpdateDevice` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDevice` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDevice, { data, loading, error }] = useUpdateDevice({
 *   variables: {
 *      id: // value for 'id'
 *      updater: // value for 'updater'
 *   },
 * });
 */
export function useUpdateDevice(baseOptions?: Apollo.MutationHookOptions<UpdateDevice, UpdateDeviceVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDevice, UpdateDeviceVariables>(UpdateDeviceDocument, options);
      }
export type UpdateDeviceHookResult = ReturnType<typeof useUpdateDevice>;
export type UpdateDeviceMutationResult = Apollo.MutationResult<UpdateDevice>;
export type UpdateDeviceMutationOptions = Apollo.BaseMutationOptions<UpdateDevice, UpdateDeviceVariables>;
export const UpdateMeDocument = gql`
    mutation UpdateMe($updater: GQLMeUpdater!) {
  updateMe(updater: $updater) {
    ...MeFragment
  }
}
    ${MeFragment}`;
export type UpdateMeMutationFn = Apollo.MutationFunction<UpdateMe, UpdateMeVariables>;

/**
 * __useUpdateMe__
 *
 * To run a mutation, you first call `useUpdateMe` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMe` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMe, { data, loading, error }] = useUpdateMe({
 *   variables: {
 *      updater: // value for 'updater'
 *   },
 * });
 */
export function useUpdateMe(baseOptions?: Apollo.MutationHookOptions<UpdateMe, UpdateMeVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMe, UpdateMeVariables>(UpdateMeDocument, options);
      }
export type UpdateMeHookResult = ReturnType<typeof useUpdateMe>;
export type UpdateMeMutationResult = Apollo.MutationResult<UpdateMe>;
export type UpdateMeMutationOptions = Apollo.BaseMutationOptions<UpdateMe, UpdateMeVariables>;
export const FetchConversationByChildIdDocument = gql`
    query FetchConversationByChildId($childId: String!) {
  conversationByChildId(childId: $childId) {
    ...ConversationFragment
  }
}
    ${ConversationFragment}`;

/**
 * __useFetchConversationByChildId__
 *
 * To run a query within a React component, call `useFetchConversationByChildId` and pass it any options that fit your needs.
 * When your component renders, `useFetchConversationByChildId` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchConversationByChildId({
 *   variables: {
 *      childId: // value for 'childId'
 *   },
 * });
 */
export function useFetchConversationByChildId(baseOptions: Apollo.QueryHookOptions<FetchConversationByChildId, FetchConversationByChildIdVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchConversationByChildId, FetchConversationByChildIdVariables>(FetchConversationByChildIdDocument, options);
      }
export function useFetchConversationByChildIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchConversationByChildId, FetchConversationByChildIdVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchConversationByChildId, FetchConversationByChildIdVariables>(FetchConversationByChildIdDocument, options);
        }
export type FetchConversationByChildIdHookResult = ReturnType<typeof useFetchConversationByChildId>;
export type FetchConversationByChildIdLazyQueryHookResult = ReturnType<typeof useFetchConversationByChildIdLazyQuery>;
export type FetchConversationByChildIdQueryResult = Apollo.QueryResult<FetchConversationByChildId, FetchConversationByChildIdVariables>;
export const FetchFamilyDocument = gql`
    query FetchFamily {
  family {
    ...FamilyFragment
  }
}
    ${FamilyFragment}`;

/**
 * __useFetchFamily__
 *
 * To run a query within a React component, call `useFetchFamily` and pass it any options that fit your needs.
 * When your component renders, `useFetchFamily` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchFamily({
 *   variables: {
 *   },
 * });
 */
export function useFetchFamily(baseOptions?: Apollo.QueryHookOptions<FetchFamily, FetchFamilyVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchFamily, FetchFamilyVariables>(FetchFamilyDocument, options);
      }
export function useFetchFamilyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchFamily, FetchFamilyVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchFamily, FetchFamilyVariables>(FetchFamilyDocument, options);
        }
export type FetchFamilyHookResult = ReturnType<typeof useFetchFamily>;
export type FetchFamilyLazyQueryHookResult = ReturnType<typeof useFetchFamilyLazyQuery>;
export type FetchFamilyQueryResult = Apollo.QueryResult<FetchFamily, FetchFamilyVariables>;
export const FetchMeDocument = gql`
    query FetchMe {
  me {
    ...MeFragment
  }
}
    ${MeFragment}`;

/**
 * __useFetchMe__
 *
 * To run a query within a React component, call `useFetchMe` and pass it any options that fit your needs.
 * When your component renders, `useFetchMe` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMe({
 *   variables: {
 *   },
 * });
 */
export function useFetchMe(baseOptions?: Apollo.QueryHookOptions<FetchMe, FetchMeVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchMe, FetchMeVariables>(FetchMeDocument, options);
      }
export function useFetchMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchMe, FetchMeVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchMe, FetchMeVariables>(FetchMeDocument, options);
        }
export type FetchMeHookResult = ReturnType<typeof useFetchMe>;
export type FetchMeLazyQueryHookResult = ReturnType<typeof useFetchMeLazyQuery>;
export type FetchMeQueryResult = Apollo.QueryResult<FetchMe, FetchMeVariables>;